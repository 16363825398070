import React from "react";

import Repository from "./Repository";

import ResetCache from "../../scripts/GitHubRepositories";

/**
 * Title: title
 * Description: description
 * Github Link: githubLink
 * Image Link: image
 * Image Alt: imageAlt
 * GitHub Status: status
 * Frameworks: frameworks (title/link)
 *
 * @param props
 * @constructor
 */
const Card = (props : any) => {
    const [frameworkList, SetFrameworkList] = React.useState([] as any);
    const [commits, SetCommits] = React.useState("Loading..");
    const [tickets, SetTickets] = React.useState("Loading..");
    const [stars, SetStars] = React.useState("Loading..");

    const FrameworkRef = (props : any) => {
        return <a href={props.link} target={"_blank"} rel={"noreferrer"}>{props.title}</a>;
    }

    function GetSecondsPassed(time : number) {
        return Math.floor((new Date().getTime() - time)/1000);
    }

    React.useEffect(() => {
        let tempArray = [] as any;

        props.frameworks.map((framework : any, index : any) => {
            if (framework.link == null) {
                return tempArray.push(<span>{framework.title}, </span>);
            }

            return tempArray.push(
                (index === props.frameworks.length - 1) ?
                    (
                        <span>
                            <FrameworkRef key={index + framework.title} link={framework.link} title={framework.title}/>
                        </span>
                    )
                    :
                    (
                        <span>
                            <FrameworkRef key={index + framework.title} link={framework.link} title={framework.title}/>, {' '}
                        </span>
                    )
            );
        });
        SetFrameworkList(tempArray);

        let localStorageRepository : (any | null) = localStorage.getItem(props.repositoryName);

        if (localStorageRepository == null) {
            localStorageRepository = {
                "time": new Date().getTime() - (1000*60*60),
                "commits": "Loading...",
                "open_issues": "Loading...",
                "stars": "Loading..."
            }
        } else {
            localStorageRepository = JSON.parse(localStorageRepository);
        }

        if (GetSecondsPassed(localStorageRepository.time) < 60*60) {
            SetCommits(localStorageRepository.commits);
            SetTickets(localStorageRepository.open_issues);
            SetStars(localStorageRepository.stars);

            // Reset
            localStorage.setItem(props.repositoryName, JSON.stringify(localStorageRepository));

            return;
        }

        let json : any;
        ResetCache(props.repositoryName).then((result) => {
            json = result;

            // Reset
            localStorageRepository.time = new Date().getTime();
            localStorageRepository.commits = json.commits.toString();
            localStorageRepository.open_issues = json.open_issues.toString();
            localStorageRepository.stars = json.stars.toString();

            SetCommits(localStorageRepository.commits);
            SetTickets(localStorageRepository.open_issues);
            SetStars(localStorageRepository.stars);

            localStorage.setItem(props.repositoryName, JSON.stringify(localStorageRepository));
        });

    }, [props.frameworks, props.repositoryName]);

    let githubLink = props.githubLink;
    let image = props.image;
    let imageAlt = props.imageAlt;

    return (
        <div className={"Card"}>
            <div className={"Left"}>
                <a href={githubLink} target={"_blank"} className={"IgnoreLink"} rel={"noreferrer"}><h2>{props.title}</h2></a>
                <p>{props.description}</p>
                <div className={"Frameworks"}>
                    <h3>Frameworks/Libraries/Tech/APIs</h3>
                    <p>{frameworkList}</p>
                </div>

                <Repository
                    githubLink={githubLink}
                    commits={commits}
                    tickets={tickets}
                    stars={stars}
                    status={props.status}
                />
            </div>

            <div className={"Right"}>
                {
                    props.rightOverride == null ?
                        <img src={image} alt={imageAlt}/>
                        :
                        props.rightOverride
                }
            </div>

        </div>
    );
}

export default Card;

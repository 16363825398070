let body = document.body;
let style = getComputedStyle(body);

let start = StringHexToNumber(style.getPropertyValue('--start'));
let end = StringHexToNumber(style.getPropertyValue('--end'));

const START_MAX_HEX = 0x6A73FF;
const START_MIN_HEX = 0x6A737E;
const END_MAX_HEX = 0xA049FF;
const END_MIN_HEX = 0xA0498E;

let startVar = 1;
let endVar = 1;

function Start() : void {
    setInterval(() => {
        start += startVar;
        end += endVar;

        // Too High Case
        if (start > START_MAX_HEX) {
            startVar = -1;
            start--;
        }

        if (end > END_MAX_HEX) {
            endVar = -1;
            end--;
        }

        // Too Low Case
        if (start < START_MIN_HEX) {
            startVar = 1;
            start++;
        }

        if (end < END_MIN_HEX) {
            endVar = 1;
            end++;
        }

        body.style.setProperty('--start', `#${start.toString(16).toUpperCase()}`);
        body.style.setProperty('--end', `#${end.toString(16).toUpperCase()}`);
    }, 60);
}

function StringHexToNumber(hexNum : string) {
    return parseInt(hexNum.replace(/^#/, ''), 16);
}

export default Start;

import "../styles/CodingProjects.scss";

import React from "react";

import Card from "./CodingProjectsCards/Card";

import codingProjectsJSON from "../json_files/CodingProjects.json";

const CodingProjects = (props : any) => {

    const [cards, SetCards] = React.useState([]);

    React.useEffect(() => {
        let tempArray = [] as any;

        codingProjectsJSON.items.map((item, index) => {
            let rightOverride : any;
            rightOverride = null;

            if (item.title === "IB Computer Science IA - Boggle")
                rightOverride = (
                    <iframe title={"boggle-video"} id={"boggle-video"} src="https://www.youtube.com/embed/aXT2JvBE37Q?html5=1&autoplay=0&mute=1"></iframe>
                );

            if (item.title === "Back to the Jungle")
                rightOverride = (
                    <a href={"https://beanbeanjuice.itch.io/back-to-the-jungle?secret=An3JsIB31Jq7KsVBXUu7CrETRUM"} target={"_blank"} rel={"noreferrer"}><img src={item.image} alt={item.imageAlt} id={"back-to-the-jungle-demo"}/></a>
                );

            return tempArray.push(
                <Card
                    key={index}
                    title={item.title}
                    description={item.description}
                    githubLink={item.githubLink}
                    repositoryName={item.repositoryName}
                    image={item.image}
                    imageAlt={item.imageAlt}
                    status={item.status}
                    frameworks={item.frameworks}
                    rightOverride={rightOverride}
                />
            );
        });

        SetCards(tempArray);
    }, []);

    return (
        <div className={"CodingProjects"}>
            {cards}
        </div>
    );
}

export default CodingProjects;

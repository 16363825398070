import "../styles/Home.scss";

const Home = (props : any) => {
    return (
        <div className={"Home"}>
            <h2>Welcome to <span>The Café</span></h2>
            <p>A place to learn about who I am, see my coding projects, and social media!</p>
            <div className={"Buttons"}>
                <button onClick={() => props.SwitchScreen("about-me")}>
                    <span>About Me</span>
                </button>
                <button onClick={() => props.SwitchScreen("coding-projects")}>
                    <span>Coding Projects</span>
                </button>
                <button onClick={() => props.SwitchScreen("social-media")}>
                    <span>Social Media</span>
                </button>
            </div>
        </div>
    );
}

export default Home;

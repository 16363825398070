import "../styles/Navbar.scss";

import React from "react";

const Navbar = (props : any) => {

    function NavbarClick(screenName : string) {
        props.SwitchScreen(screenName);
    }

    function IsCurrentScreen(screenName : string) {
        return props.screenName === screenName;
    }

    return (
        <div className={"Navbar"}>
            <div className={"Container"}>
                <a onClick={() => props.SwitchScreen("home")} className={"Logo"}>The Café</a>

                <div className={"HamburgerContainer"}>
                    <input type={"checkbox"} className={"ToggleMenu"}/>
                    <div className={"Hamburger"}></div>

                    <ul className={"Menu"}>
                        <li className={`MenuItem`}>
                            <button onClick={() => NavbarClick("home")}>Home</button>
                        </li>

                        <li className={`MenuItem ${IsCurrentScreen("about-me") ? "Selected" : ""}`}>
                            <button onClick={() => NavbarClick("about-me")}>About Me</button>
                        </li>

                        <li className={`MenuItem ${IsCurrentScreen("coding-projects") ? "Selected" : ""}`}>
                            <button onClick={() => NavbarClick("coding-projects")}>Coding Projects</button>
                        </li>

                        <li className={`MenuItem ${IsCurrentScreen("social-media") ? "Selected" : ""}`}>
                            <button onClick={() => NavbarClick("social-media")}>Social Media</button>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    );
}

export default Navbar;

import "../styles/SocialMedia.scss";

import githubImage from "../images/icons/github.png";
import youtubeImage from "../images/icons/youtube.png";
import twitchImage from "../images/icons/twitch.png";
import discordImage from "../images/icons/discord.png";
import steamImage from "../images/icons/steam.png";
import instagramImage from "../images/icons/instagram.png";

function SocialMedia() {
    return (
        <div className={"SocialMediaCards"}>
            <a href="https://www.github.com/beanbeanjuice" target="_blank" rel={"noreferrer"} className={"Card"}>
                <h3>GitHub</h3>
                <img src={githubImage} alt="Github Icon"/>
            </a>

            <a href="https://www.youtube.com/beanbeanjuice" target="_blank" rel={"noreferrer"} className={"Card"}>
                <h3>YouTube</h3>
                <img src={youtubeImage} alt="YouTube Icon"/>
            </a>

            <a href="https://www.twitch.tv/beanbeanjuice" target="_blank" rel={"noreferrer"} className={"Card"}>
                <h3>Twitch</h3>
                <img src={twitchImage} alt="Twitch Icon"/>
            </a>

            <a href="https://discord.gg/KrUFw3uHST" target="_blank" rel={"noreferrer"} className={"Card"}>
                <h3>Discord</h3>
                <img src={discordImage} alt="Discord Icon"/>
            </a>

            <a href="https://steamcommunity.com/id/beanbeanjuice/" target="_blank" rel={"noreferrer"} className={"Card"}>
                <h3>Steam</h3>
                <img src={steamImage} alt="Steam Icon"/>
            </a>

            <a href="https://www.instagram.com/beanbeanjuice" target="_blank" rel={"noreferrer"} className={"Card"}>
                <h3>Instagram</h3>
                <img src={instagramImage} alt="Instagram Icon"/>
            </a>
        </div>
    );
}

export default SocialMedia;
